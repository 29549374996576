<template>
  <v-select @update:model-value="recordChange">
    <template
      v-for="(_, slot) in $slots"
      #[slot]="slotProps"
    >
      <slot
        :name="slot"
        v-bind="{...slotProps}"
      />
    </template>
  </v-select>
</template>

<script>
export default {
  name: "GSelect",
  props: {
    metric: { type: String, required: true },
  },
  methods: {
    recordChange(value) {
      this.$metrics.click(`${this.metric}/${value}`)
    },
  },
}
</script>
