<template>
  <div>
    <v-snackbar
      v-for="notification in notifications"
      :key="notification.id"
      :class="toastClass"
      :color="notification.color"
      data-cy="glyue-notification"
      :location="notification.location ?? 'bottom right'"
      :model-value="notification.show"
      :timeout="-1"
      max-width="400px"
    >
      <div class="d-flex align-center">
        <v-icon
          v-if="notification.icon"
          :icon="notification.icon"
          start
        />
        <div>
          <div
            v-if="notification.msg"
            class="toast-msg"
          >
            {{ notification.msg }}
          </div>
          <div v-if="notification.link">
            <a
              class="text-primary flex-grow-1 flex-shrink-0"
              :href="notification.link.href"
              target="_blank"
              rel="noopener"
            >
              {{ notification.link.text }}
            </a>
          </div>
        </div>
      </div>
      <template #actions>
        <g-btn
          v-if="notification.showClose"
          density="comfortable"
          icon
          metric="toast/close"
          variant="text"
          @click="close(notification.id)"
        >
          <v-icon>mdi-close</v-icon>
        </g-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapStores } from "pinia"
import { useNotificationStore } from "../stores/notification"

export default {
  computed: {
    notifications() {
      return this.notificationStore.notifications
    },
    toastClass() {
      return this.$route.name === "build" || this.$route.name === "diff"
        ? "toast-container"
        : ""
    },
    ...mapStores(useNotificationStore),
  },
  methods: {
    close(id) {
      this.notificationStore.removeNotificationById(id)
    },
  },
}
</script>

<style scoped>
.toast-msg {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toast-container {
  padding: 0px 12px 65px 56px !important;
}
</style>
