<template>
  <v-list-item
    data-cy="notification-list-item"
    lines="three"
    active-class="active--notification"
    :exact="false"
    :active="isActive"
    :href="notification.notification.url"
    @click="selectNotification"
  >
    <v-list-item-title>{{ notification.notification.content }}</v-list-item-title>
    <v-list-item-subtitle>{{ notification.notification.content_detail }}</v-list-item-subtitle>
    <v-list-item-action class="d-flex justify-space-between">
      {{ notificationDate }}
      <g-btn
        data-cy="notification-read-button"
        metric="notification/read"
        size="small"
        icon
        variant="text"
        :tooltip="`Mark as ${ notification.read_at ? 'Unr' : 'R' }ead`"
        @click.stop.prevent="(e) => updateNotificationReadStatus(e, !notification.read_at)"
      >
        <v-icon :color="notification.read_at ? 'grey' : 'blue'">
          mdi-circle
        </v-icon>
      </g-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { formatDate } from '../../views/common'
import { updateUserNotificationById } from '../../api/user'
export default {
  props: {
    notification: { type: Object, default: () => {}},
    isActive: Boolean,
  },
  computed: {
    notificationDate: function () {
      return formatDate(this.notification.notification.created_at, true)
    }
  },
  methods: {
    async updateNotificationReadStatus(e, isRead) {
      if (this.notification.read_at === isRead) return
      const [err] = await updateUserNotificationById(this.notification.id, {"isRead": isRead})
      if (err) {
        console.error(err)
        return
      }
      this.$emit('update:notification', this.notification.id, { read_at: !this.notification.read_at })
    },
    async selectNotification() {
      await this.updateNotificationReadStatus(true)
      this.$emit('select:notification', this.notification.id)
    }
  }
}
</script>