import axios from 'axios'
import { instance } from './index.js'
import { createFormBody, getCookieValue } from '../views/common.js'


export async function checkUser() {
  try {
    const response = await instance.get('auth/check/')
    return [ null, response.data ]
  } catch (err) {
    return [ err ]
  }
}

export async function loginUser(username, password) {

  try {
    const response = await axios({
      url: "/integrations/services/auth/login/",
      method: "POST",
      validateStatus: (status) => { return status === 200 },
      data: createFormBody({
        user: username,
        pswd: password
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRFToken": getCookieValue('csrftoken')
      }
    })
    return [ null, response.data ]
  } catch (error) {
    return [ error ]
  }
}
