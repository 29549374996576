<template>
  <div>
    <v-dialog
      v-model="showLoadingDialog"
      width="25%"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 text-md-h5 text-lg-h4">
          Please Wait...
        </v-card-title>
        <p class="font-weight-regular text-subtitle-1 px-7 py-4 mb-0">
          We are sending your feedback.
        </p>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showFeedbackConfirmDialog"
      width="50%"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 text-md-h5 text-lg-h4 pt-6">
          Give Feedback
        </v-card-title>
        <p class="font-weight-regular text-subtitle-1 mx-8 mt-4">
          Your thoughts are valuable in helping to improve
          your overall Glyue experience. Any feedback or suggestion provided will be processed by our support and
          product team.
        </p>
        <p class="font-weight-regular text-subtitle-1 mx-8 mt-4">
          If you have any questions related to integration
          logic
          or
          run support, please use our <a
            v-if="help_center_url"
            :href="help_center_url"
            target="_blank"
            class="text-decoration-none text-primary"
          >Help Center</a> instead.
        </p>
        <v-textarea
          v-model="description"
          class="mx-8"
          label="Let us know what's on your mind"
          auto-grow
          variant="outlined"
          rows="10"
          row-height="20"
          bg-color="grey-lighten-2"
          data-cy="feedback-form-description"
        />
        <div class="d-flex flex-row justify-space-between mx-8 pb-10">
          <div class="d-flex flex-row">
            <v-tooltip location="bottom left">
              <template #activator="{ props }">
                <v-file-input
                  v-model="files"
                  style="height: 40px"
                  multiple
                  flat
                  variant="solo"
                  v-bind="props"
                />
              </template>
              <span>Add Attachments</span>
            </v-tooltip>
          </div>
          <div>
            <g-btn
              metric="feedback/submit/cancel"
              class="mr-2 mt-3"
              data-cy="feedback-form-cancel"
              @click="showFeedbackConfirmDialog = false"
            >
              Cancel
            </g-btn>
            <g-btn
              :metric="`feedback/submit${pathname}`"
              class="mt-3"
              :disabled="!description"
              data-cy="feedback-form-send"
              type="submit"
              @click="sendFeedback()"
            >
              Send
            </g-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <g-btn
      metric="app/feedback"
      color="grey"
      icon
      tooltip="Give Feedback or Suggestions"
      @click="showFeedbackConfirmDialog = true"
    >
      <v-icon>
        mdi-bullhorn
      </v-icon>
    </g-btn>
  </div>
</template>
  
<script>
import axios from "axios"
import { getCookieValue } from "../../views/common";
import metrics from "../../metrics";
import { mapStores } from 'pinia'
import { useNotificationStore } from '@/stores/notification'
import { getFeedbackIntro } from '../../introUtil'

export default {
    props: {
        jira_enabled: { type: Boolean, default: false },
        help_center_url: {
            type: String,
            default: null,
            validator: (value) => {
                try {
                    return !!new URL(value) // Validate URL
                } catch {
                    return false // Invalid
                }
            } 
        },
        open: { type: Boolean, default: false } // Useful for testing
    },
    data() {
        return {
            showFeedbackConfirmDialog: this.open ?? false,
            showLoadingDialog: false,
            description: "",
            metrics,
            pathname: window.location.pathname,
            files: [],
            showTooltip: false
        }
    },
    computed: {
        ...mapStores(useNotificationStore)
    },
    watch: {
        showFeedbackConfirmDialog: {
            handler(feedback) {
                if (!feedback && this.showLoadingDialog === false) {
                    this.description = ""
                    this.files = []
                } else if (feedback && this.showLoadingDialog === false) {
                    setTimeout(() => {
                        this.tryStartTutorial()
                    }, 500);
                }
            }
        },
        showLoadingDialog: {
            handler(loading) {
                if (!loading && this.showFeedbackConfirmDialog === false) {
                    this.description = ""
                    this.files = []
                }
            }
        }
    },
    methods: {
        sendFeedback() {
            this.showFeedbackConfirmDialog = false
            this.showLoadingDialog = true
            let formData = new FormData()

            for (let file of this.files) {
                formData.append("files", file)
            }

            formData.append("description", this.description)
            formData.append("current_page", window.location.href)

            axios({
                url: "/integrations/services/feedback",
                method: "POST",
                credentials: "include",
                headers: {
                    "X-CSRFToken": getCookieValue("csrftoken"),
                },
                data: formData
            }).then((res) => {
                setTimeout(() => {
                    this.showLoadingDialog = false
                    this.notificationStore.addNotification({
                        msg: "Your feedback has been sent.",
                        color: "success",
                        showClose: false,
                    });
                }, 500);
            }).catch((err) => {
                setTimeout(() => {
                    this.showFeedbackConfirmDialog = true
                    this.showLoadingDialog = false
                    let errors = "An unknown error occurred while sending your feedback."

                    if (err?.response?.data?.errors) {
                        errors = err.response.data.errors
                    } else if (err.response.status === 401) {
                        errors = "Not Authenticated"
                    }

                    this.notificationStore.addNotification({
                        msg: errors,
                        color: "error",
                        showClose: false,
                    });
                    console.error(errors);
                }, 500);
            });
        },
        tryStartTutorial() {
            const intro = getFeedbackIntro()
            if (!intro) return
            intro.start()
        }
    }
}
</script>