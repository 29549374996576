import { setMultiEnviornmentCookie, getCookieValue } from "@/views/common"
import { getUserPreferences as getDBUserPreferences, updateUserPreference } from '@/api/user'

const definitions = {
    "landing-page": {
        items: {
            "dashboard": { text: "Dashboard", value: "dashboard" },
            "build": { text: "Build", value: "build" },
            "history": { text: "Run History", value: "history" },
            "bookmarks": { text: "Bookmarks", value: "bookmarks" },
        },
        default: "dashboard",
        prefType: ["cookie"]
    },
    "cell-font-family": {
        items: {
            "default": { text: "Default", value: false },
            "monospace": { text: "Monospace", value: 'Consolas, "Liberation Mono", Courier, monospace' }
        },
        default: "default",
        prefType: ["cookie"]
    },
    "cell-scale": {
        items: {
            "default": { text: "Default", value: { rowHeight: 36, fontSize: "14px" } },
            "compact": { text: "Compact", value: { rowHeight: 28, fontSize: "12px" } },
            "very-compact": { text: "Very Compact", value: { rowHeight: 20, fontSize: "12px" } }
        },
        default: "default",
        prefType: ["cookie"]
    },
    "use_email": {
        items: {
            "enable": { text: "Enable", value: true },
            "disable": { text: "Disable", value: false }
        },
        default: "disable",
        prefType: ["db"],
        convertFn: (value) => {
            if (typeof value === "boolean") { return value ? "enable" : "disable" }
            return value === "enable"
        }
    },

}

export const getPreferenceVSelect = (prefName) => {
    return Object.entries(definitions[prefName].items).map(([prefKey, prefValues]) => {
        return { title: prefValues.text, value: prefKey }
    })
}

export const getPreferenceKey = (prefName) => {
    let preferences = {}
    try {
        preferences = JSON.parse(getCookieValue("preferences"))
    } catch { /* */ }

    let prefKey = preferences[prefName]
    if (!definitions[prefName].items[prefKey]) {
        prefKey = definitions[prefName].default
    }
    return prefKey
}

export const getPreferenceValue = (prefName) => {
    return definitions[prefName].items[getPreferenceKey(prefName)]?.value
}

export const getPreferenceKeys = async (queryDB = false) => {
    let out = {}
    Object.keys(definitions)
        .filter(prefName => definitions[prefName].prefType?.includes('cookie'))
        .forEach(prefName =>
            out[prefName] = getPreferenceKey(prefName)
        )

    if (queryDB) {
        const [err, dbPreferences] = await getDBUserPreferences()
        if (err){
            console.error(err)
            return out
        }

        Object.keys(dbPreferences).forEach(pref => {
            out[pref] = definitions[pref].convertFn ? definitions[pref].convertFn(dbPreferences[pref]) :
                dbPreferences[pref]
        })
    }
    return out
}

export const setPreference = async (prefName, prefKey) => {
    if (definitions[prefName]?.prefType?.includes('db')) {
        let prefValue = definitions[prefName].convertFn ? definitions[prefName].convertFn(prefKey) : prefKey
        const [err] = await updateUserPreference(prefName, prefValue)
        if (err) {throw err}
    }

    getPreferenceKeys(false).then(preferences => {
        preferences[prefName] = prefKey
        setMultiEnviornmentCookie("preferences", JSON.stringify(preferences))
    }).catch(error => {
        console.error(error)
    })
}
