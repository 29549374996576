<template>
  <v-list v-model:opened="open">
    <v-list-item v-if="searching">
      Searching...
    </v-list-item>
    <v-list-item
      v-else-if="status !== 'complete'"
      class="d-flex justify-space-between"
      target="_blank"
      href="https://glyue.docs.sandboxbanking.com"
    >
      Search Platform Documentation for Glyue related help.
      <v-icon>mdi-open-in-new</v-icon>
    </v-list-item>
    <template v-if="gitbookResults.length > 0">
      <v-list-group
        v-for="searchResult in gitbookResults"
        :key="searchResult.id"
        class="px-0"
        :value="searchResult.id"
      >
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            class="pl-2"
            data-cy="gitbook-search-categories"
          >
            <v-list-item-title>
              <v-btn 
                class="text-body-1" 
                :href="searchResult.url" 
                variant="text" 
                target="_blank"
              >
                <v-icon class="pr-1">
                  mdi-open-in-new
                </v-icon>
                {{ searchResult.title }} 
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item
          v-for="section in searchResult.sections"
          :key="section.id"
          class="pl-8"
          target="_blank"
          :href="section.url"
        >
          <v-list-item-title>
            {{ section.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ section.body }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list-group>
    </template>
    <v-list-item v-else-if="status==='complete' && search">
      No results
    </v-list-item>
  </v-list>
</template>

<script>
import { getSearchDocumentation } from "../api/documentation"
import debounce from "debounce"
export default {
  expose: [ 'cancelSearch' ],
  props: {
    search: {
      required: true,
      validator: val => typeof val === "string" || val === null      
    },
    searching: {
      type: Boolean,
      required: true,
    },
    status: {
      type: String,
      required: true,
    }
  },
  emits: {'update:status': null, 'update:error': null},
  data() {
    return {
      open: [],
      gitbookResults: [],
      controller: null,
      lastSearch: "",
    }
  },
  watch: {
    search: {
      handler: function (){
        this.searchGitbook()
      }
    },
  },
  activated() {
    if (this.search && this.lastSearch !== this.search) {
      this.searchGitbook()
    }
  },
  methods: {
    searchGitbook: debounce(async function() {
      this.cancelSearch()
      if (!this.search) {
        this.$emit("update:status", "idle")
        this.$emit("update:error", "")
        return
      }
      this.controller = new AbortController()
      this.$emit("update:status", "searching")
      this.$metrics.enterText('app/docsearch/gitbook', this.search)
      const [err, data] = await getSearchDocumentation(this.search, {
        params: {
          page: this.page,
          limit: this.limit,
          type: 'gitbook',
        },
        signal: this.controller.signal
      })
      this.controller = null
      if (err) {
        this.$emit("update:error", err.error ?? err)
        return
      }
      this.$emit("update:status", "complete")
      this.lastSearch = this.search
      this.gitbookResults = data.items
    }, 500),
    cancelSearch(){
      if (this.controller) {
        this.controller.abort()
        this.controller = null
      }
      this.gitbookResults = []
    }
  },
}

</script>