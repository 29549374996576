<template>
  <v-checkbox @update:model-value="recordChange">
    <template
      v-for="(_, slot) in $slots"
      #[slot]="slotProps"
    >
      <slot
        :name="slot"
        v-bind="{...slotProps}"
      />
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "GCheckbox",
  props: {
    metric: { type: String, default: null },
  },
  methods: {
    recordChange(value) {
      if (this.metric) {
        this.$metrics.toggle(this.metric, value)
      }
    },
  },
}
</script>
