<template>
  <v-app>
    <v-app-bar
      :color="installationStore.app_color"
      elevation="0"
      border
      class="justify-space-between"
    >
      <v-app-bar-title>
        <template v-if="installationStore.logo_src">
          <router-link to="/">
            <img
              alt="Uploaded Logo"
              :src="installationStore.logo_src"
              style="max-height:50px;max-width:175px"
            />
          </router-link>
          <v-icon style="height:30px;padding-bottom:30px;padding-left:20px;padding-right:20px">
            mdi-plus
          </v-icon>
        </template>
        <router-link to="/">
          <img
            alt="Sandbox Logo"
            src="./media/sandbox_banking_logo.png"
            style="max-height:50px;max-width:175px"
          />
        </router-link>
      </v-app-bar-title>
      <div
        v-if="userStore.connected"
        class="d-flex justify-end"
      >
        <search-documentation-menu
          v-if="smAndUp"
          :width="mdAndUp ? '500px' : '320px'"
        />
        <g-btn
          icon
          color="grey"
          metric="app/glyue-help-center"
          target="_blank"
          :href="installationStore.help_center_url"
          tooltip="Help Center"
        >
          <v-icon>mdi-help-circle</v-icon>
        </g-btn>
        <feedback-dialog
          :jira_enabled="installationStore.jira_enabled"
          :help_center_url="installationStore.help_center_url"
        />
        <notification-menu />
        <account-menu
          :admin="userStore.admin"
          :staff="userStore.staff"
          :git="installationStore.git"
        />
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-if="userStore.connected"
      :expand-on-hover="true"
      permanent
      data-cy="app-navigation-bar"
      :rail="true"
    >
      <v-list
        v-model="currentPage"
        density="compact"
      >
        <v-list-item
          v-for="item in userNavigationLinks"
          :key="item.text"
          data-cy="app-nav-link"
          :to="item.ref"
          :value="item.ref"
          :prepend-icon="item.icon"
          :title="item.text"
        />
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-banner
        v-if="notificationStore.banners.length"
        v-bind="props"
        class="glyue-banner"
        rounded
      >
        <v-sheet
          elevation="1"
          color="white"
        >
          {{ notificationStore.banners[0].text }}
        </v-sheet>
        <template #actions="{ dismiss }">
          <g-btn
            variant="text"
            color="primary"
            @click="dismiss(); notificationStore.removeBanner()"
          >
            <v-icon>mdi-close</v-icon>
            Dismiss
          </g-btn>
        </template>
      </v-banner>
      <v-container
        v-if="loading"
        class="fill-height justify"
        fluid
      >
        <v-row
          :align="'center'"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="100"
          >
            <v-img
              :src="logo"
              :height="50"
              cover
            />
          </v-progress-circular>
        </v-row>
      </v-container>
      <router-view
        v-else
        @loading-hide="loadingHide"
      />
    </v-main>
    <glyue-toast ref="gtoast" />
  </v-app>
</template>

<script>
import { mapStores } from 'pinia'
import { useDisplay } from "vuetify"
import GlyueToast from './components/GlyueToast.vue'
import SearchDocumentationMenu from "./components/SearchDocumentationMenu.vue"
import AccountMenu from "./components/maintemplate/AccountMenu.vue"
import FeedbackDialog from "./components/maintemplate/FeedbackDialog.vue"
import NotificationMenu from "./components/maintemplate/NotificationMenu.vue"
import logo from './media/logo.png'
import { useInstallationStore } from "./stores/installation"
import { useNotificationStore } from "./stores/notification"
import { useUserStore } from "./stores/user"

export default {
  components: {
    GlyueToast,
    AccountMenu,
    FeedbackDialog,
    NotificationMenu,
    SearchDocumentationMenu,
  },
  setup(){
    const { mdAndUp, smAndUp } = useDisplay()
    return { mdAndUp, smAndUp }
  },
  data() {
    return {
      loading: true,
      logo: logo,
      showDrawer: "",
      git: null,
      currentPage: -1,
      items: [
        { icon: "mdi-view-dashboard", text: "Dashboard", ref: "/dashboard" },
        { icon: "mdi-bookmark-multiple", text: "Bookmarks", ref: "/bookmarks" },
        { icon: "mdi-history", text: "Run History", ref: "/history" },
        { icon: "mdi-wrench", text: "Build", ref: "/build"},
        { icon: "mdi-api", text: "Swagger API", ref: "/swagger" },
        { icon: "mdi-sitemap", text: "Frontends", ref: "/frontends", permissions: ["view_frontend"] },
        { icon: "mdi-safe-square-outline", text: "Vault", ref: "/vault", vault: true },
        { icon: "mdi-briefcase", text: "Jobs", ref: "/jobs", admin: true },
        { icon: "mdi-calendar-clock", text: "Schedule", ref: "/schedules/dashboard" },
        { icon: "mdi-folder-move", text: "Migrate", ref: "/migrate", staff: true },
        { icon: "mdi-book-open-page-variant-outline", text: "Catalog", ref: "/catalog", catalogEnabled: true },
        { icon: "mdi-file-compare", text: "View Changes", ref: "/diff", staff: true },
        { icon: "mdi-text-long", text: "Log", ref: "/log", admin: true },
        { icon: "mdi-vpn", text: "OpenVPN", ref: "/openvpn", admin: true },
        { icon: "mdi-account-plus", text: "Invite", ref: "/invite", staff: true },
      ],
    }
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useInstallationStore),
    ...mapStores(useNotificationStore, ['banners']),
    userNavigationLinks() {
      return this.items.filter(v => {
        const  adminOkay = !v.admin || (this.userStore.admin)
        const  staffOkay = !v.staff || (this.userStore.staff || this.userStore.admin)
        const  vaultOkay = !v.vault || this.userStore.vault
        const  catalogOkay = !v.catalogEnabled || this.installationStore.catalogEnabled
        const permissionsOkay = !v.permissions || v.permissions.every((permission) => this.userStore.model_permissions.has(permission))

        return adminOkay && vaultOkay && catalogOkay && staffOkay && permissionsOkay
      })
    },
  },
  watch: {
    '$route.name': function () {
      this.currentPage = this.$route.path
    }
  },
  mounted() {
    this.items.forEach((item) => {
      const page = item.ref.substring(1)

      this.$keybindAdd(
        `nav-${page}`,
        () => this.$router.push(item.ref),
        { metric: `nav/${page}` }
      )
    })

    this.$keybindAdd(
      "nav-preferences",
      () => this.$router.push("/preferences"),
      { metric: "nav/preferences" }
    )

    this.$router.beforeEach((to, from, next) => {
      if (to.path !== from.path) {
        this.loading = true
      }
      next()
    })
    this.$router.afterEach(() => {
      this.$metrics.route(window.location.pathname)
      this.loadingHide()
    })
  },
  methods: {
    loadingHide() {
      this.loading = false
    },
  },
}
</script>

<style>
/* vuetify forces scrollbar to always show with the css reset package it uses */
/* https://vuetifyjs.com/en/getting-started/frequently-asked-questions/#scrollbar-overflow */
html {
  overflow-y: auto !important;
}

.introjs-tooltip {
  min-width: 350px !important;
  max-width: 400px !important;
}

.glyue-banner {
  position: absolute;
  width: 92%;
  left: 4%;
  top: 2%;
  z-index: 99;
  opacity: 95%;
  background-color: white !important;
}

.dp__theme_light {
  --dp-primary-color: #02b8a6 !important;
}
</style>
