import { instance } from './index.js'

export const getSearchDocumentation = async (searchtext, options={}) => {

  const queryParams = new URLSearchParams({ query: searchtext })
  for (const [key, value] of Object.entries(options.params)) {
    if (value) {
      queryParams.append(key, value)
    }
  }
  delete options.params
  
  try {
    const response = await instance.get('/docs/search', { ...options, params: queryParams})
    return [null, response.data]
  } catch (err) {
    return (err.response && err.response.data) ? [err.response.data] : [err]
  }
}