<template>
  <v-btn
    v-bind="$attrs"
    ref="btn"
    :data-cy="testId"
    @click="handleClick"
  >
    <template
      v-for="(, slot) in $slots"
      #[slot]="slotProps"
    >
      <slot
        v-if="slot !== 'tooltip'"
        v-bind="slotProps"
        :name="slot"
      />
    </template>
  </v-btn>
  <v-tooltip
    v-if="tooltip || hotkey"
    v-bind="tooltipProps"
    :activator="$refs.btn"
    :location="position"
  >
    <slot name="tooltip">
      {{ tooltipText }}
    </slot>
  </v-tooltip>
</template>

<script>
import { useKeybind } from "@/keybinds"
import metrics from "@/metrics"

export default {
  props: {
    hotkey: { type: String, default: "" },
    tooltip: { type: String, default: "" },
    position: { type: String, default: "bottom" },
    tooltipProps: { type: Object, default: () => ({}) },
    metric: { type: String, default: "" },
    modal: { type: Boolean, default: false },
    dataCy: { type: String, default: "" },
    datacy: { type: String, default: "" },
  },
  emits: ["click"],
  setup(props, ctx) {
    const handleClick = (event) => {
      if (ctx.attrs.disabled) return false
      if (props.modal && document.querySelectorAll(".v-overlay--active:not(.v-tooltip)").length > 0) return false

      if (props.metric) {
        metrics.click(props.metric)
      }

      ctx.emit(
        "click",
        event.type === "keydown"
          ? new MouseEvent("mousedown") // This does not account for element placement, which breaks activators
          : event
      )
    }

    if (props.hotkey) {
      useKeybind(props.hotkey, handleClick, { metric: props.metric || undefined })
    }

    return {
      handleClick,
    }
  },
  computed: {
    hotkeyText() {
      const hotkey = this.hotkey
        ? this.$keybindGetKeyCombo(this.hotkey)
        : null

      return hotkey ? `[${hotkey}]` : ""
    },
    tooltipText() {
      return `${this.tooltip} ${this.hotkeyText}`.trim()
    },
    testId() {
      return this.dataCy || this.datacy || this.hotkey
    },
  },
}
</script>
