import axios from "axios"
import { getCookieValue } from "./views/common"

let loggedIn = false

function trunc(value) {
    if (typeof(value) != "string") return value
    return value.substring(0, 512)
}

//acts equivalent to analytics[type](args) except the data is proxied through Django
function proxyAnalytics(type, args){
    if(!loggedIn) return
    axios({
        url: '/integrations/services/metrics/proxy',
        method: 'POST',
        credentials: 'include',
        headers: {
        "X-CSRFToken": getCookieValue('csrftoken')
        },
        data: {type,args}
  }).catch(error => {
    console.debug("Failed To report metric", {type, args}, String(error.response.data))
    loggedIn = false
  })
}

let session_start = Date.now()
function reportUIMetric(type, args) {
    //This function is a wrapper over all the analytics functions
    //useful if you want to send the data somewhere else (like django)
    if(type == "track") {
        let properties = args[1] = args[1] || {}
        properties.session_start = session_start
    }
    if(type == "page") {
        let properties = args[2] = args[2] || {}
        properties.session_start = session_start
    }
    proxyAnalytics(type, args)
}
let identity = null
function metricUsername(username) {
    if(identity) return;
    identity = username + "@" + window.location.host
    reportUIMetric("identify", identity)
}

let metrics = {
    click : function(button_name) {
        let properties = {
            button_name,
        }
        reportUIMetric("track", ["Button Pressed", properties])
    },
    hotkey : function(button_name, hotkey_name) {
        let properties = {
            button_name,
            hotkey_name,
        }
        reportUIMetric("track", ["Hotkey Pressed", properties])
    },
    toggle : function(toggle_name, state) {
        let properties = {
            toggle_name,
            state,
        }
        reportUIMetric("track", ["Toggle Changed", properties])
    },
    route : function(route_name) {
        reportUIMetric("page", [null, route_name])
    },
    loadtime : function(request_name, uri, load_time_ms) {
        let properties = {
            request_name,
            uri,
            load_time_ms,
        }
        reportUIMetric("track", ["Data Loaded", properties])
    },
    username : metricUsername, //ignored serverside when using proxy
    login : function(username) {
        session_start = Date.now()
        this.setLoggedIn(true)
        metricUsername(username)
        reportUIMetric("track", ["User Login"])
    },
    logout : function() {
        reportUIMetric("track", ["User Logout"])
        //window.analytics.reset()
    },
    editCell : function(table, column, row) {
        let properties = {table, column, row}
        reportUIMetric("track", ["Cell Edited", properties])
    },
    columnResize : function(table, column, old_size, new_size) {
        let properties = {table, column, old_size, new_size}
        reportUIMetric("track", ["Column Resized", properties])
    },
    addBookmark : function(name, section, description, url, external) {
        description = trunc(description)
        let properties = {name, section, description, url, external}
        reportUIMetric("track", ["Bookmark Added", properties])
    },
    editBookmark : function(old_name, name, old_section, section, old_description, description, old_url, url, old_external, external) {
        old_description = trunc(old_description)
        description = trunc(description)
        let properties = {old_name, name, old_section, section, old_description, description, old_url, url, old_external, external}
        reportUIMetric("track", ["Bookmark Edited", properties])
    },
    deleteBookmark : function(name, section, description, url, external) {
        description = trunc(description)
        let properties = {name, section, description, url, external}
        reportUIMetric("track", ["Bookmark Deleted", properties])
    },
    followBookmark : function(name, section, url, external) {
        //Following external bookmarks is not implemented
        let properties = {name, section, url, external}
        reportUIMetric("track", ["Bookmark Followed", properties])
    },
    multiSelect : function(name, values) {
        reportUIMetric("track", ["Values Selected", {name, values:values.join()}])
    },
    setLoggedIn(state) {
        loggedIn = state
    },
    enterText(name, text) {
        reportUIMetric("track", ["Text Entered", {name, text}])
    },
    changeStatus(table, from, to) {
        reportUIMetric("track", ["Status Changed", {table, from, to}])
    },
    catalogSystemViewed(system_name) {
        reportUIMetric("track", ["Catalog System Viewed", {system_name}])
    },
    catalogIntegrationInstallStarted(system_name, integration_path_name) {
        reportUIMetric("track", ["Catalog Integration Install Started", {system_name, integration_path_name}])
    },
    catalogIntegrationInstallComplete(system_name, integration_path_name, error_message){
        reportUIMetric("track", ["Catalog Integration Install Complete", {system_name, integration_path_name, error_message}])
    },
    catalogContactUsButtonPressed(system_name, search_text){
        reportUIMetric("track", ["Catalog Contact Us Button Pressed", {system_name, search_text}])
    },
    introSeen(name, step, step_title, step_count, completed) {
        reportUIMetric("track", ["Intro Seen", {name, step, step_title, step_count, completed}])
    },
}

//Do not modify without also modifying metrics.py
export default metrics

export const metricsPlugin = {
    install: (app) => {
        app.config.globalProperties.$metrics = metrics
    }
  }
