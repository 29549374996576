<template>
  <v-menu
    v-model="showMenu"
    location="bottom"
    :close-on-content-click="false"
    :open-on-click="false"
    min-width="auto"
    max-width="50vw"
    max-height="800"
    
    @click:outside="onClickOutside"
  >
    <template #activator="{ props }">
      <v-text-field
        v-model="search"
        :style="{ width }"
        control-ref="searchInput"
        hide-details
        data-cy="appbar-search-textfield"
        label="Search Documentation"
        variant="outlined"
        single-line
        density="compact"
        clearable
        v-bind="props"
        @click:clear="resetSearch"
        @click="tryOpen"
      >
        <template #prepend-inner>
          <v-progress-circular
            v-if="searching"
            color="primary"
            size="20"
            indeterminate
          />
          <v-icon v-else>
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <keep-alive>
      <v-card
        data-cy="appbar-search-card"
      >
        <v-banner
          v-if="error"
          class="text-white"
          data-cy="appbar-search-error"
          bg-color="error"
        >
          {{ error }}
        </v-banner>
        <v-toolbar
          flat
          dense
        >
          <v-tabs v-model="docType">
            <v-tooltip color="black">
              <template #activator="{ props }">
                <v-tab
                  data-cy="docs-glyue"
                  value="gitbook"
                  v-bind="props"
                >
                  Glyue Docs
                </v-tab>
              </template>
              <span>Search documentation for Glyue platform related help.</span>
            </v-tooltip>
            <v-tooltip color="black">
              <template #activator="{ props }">
                <v-tab
                  data-cy="docs-support"
                  value="atlassian"
                  v-bind="props"
                >
                  Support Center
                </v-tab>
              </template>
              <span>Search for errors and help for system related information</span>
            </v-tooltip>
          </v-tabs>
        </v-toolbar>
        <v-card-text class="pa-0">
          <keep-alive>
            <gitbook-search
              v-if="docType === 'gitbook'"
              ref="gitbook"
              :search="search"
              :searching="searching"
              :status="status"
              @update:status="setStatus"
              @update:error="setSearchError"
              @click="docType = 'gitbook'"
            />
            <atlassian-search
              v-else
              ref="atlassian"
              :search="search"
              :searching="searching"
              :status="status"
              @update:status="setStatus"
              @update:error="setSearchError"
              @click="docType = 'atlassian'"
            />
          </keep-alive>
        </v-card-text>
      </v-card>
    </keep-alive>
  </v-menu>
</template>
<script>
import GitbookSearch from "./GitbookSearch.vue"
import AtlassianSearch from "./AtlassianSearch.vue"
export default {
  components: {
    GitbookSearch,
    AtlassianSearch,
  },
  props: {
    width: {
      type: String,
      default: "300px",
    },
  },
  data: function () {
    return {
      status: "idle",
      showMenu: false,
      search: "",
      error: "",
      docType: "gitbook",
    }
  },
  computed: {
    searching() {
      return this.status === 'searching'
    }
  },
  methods: {
    onClickOutside() {
      this.showMenu = false;
      this.cancelSearches()
    },
    tryOpen() {
      if (!this.showMenu) {
        this.showMenu = true;
      }
    },
    setStatus(status) {
      this.status = status
    },
    resetSearch() {
      this.search = ""
      this.error = ""
      this.loading = false
      this.status = "idle"
    },
    setSearchError(err) {
      if (err && err.message === "canceled") {
        this.status = "idle"
        return
      }
      this.status = "error"
      this.error = err
    },
    cancelSearches(){
      for (const ref in this.$refs) {
        if (this.$refs[ref]) {
          this.$refs[ref].cancelSearch()
        }
      }
    }
  }
}
</script>